<template lang="pug">
Header
Main
	Container
		div(class='ServerErrorPage')
			h1 {{ errorCode }}
			div(class='Description') Внутренняя ошибка сервера
			UiButton(to='/', size='xl', rounded) Вернуться на главную

Footer
</template>

<script lang="ts" setup>
defineComponent({
	name: 'ServerErrorPage',
});

const error = useError();

const errorCode = computed(() => (error.value ? error.value.statusCode : 0));
</script>

<style lang="less" scoped>
.ServerErrorPage {
	padding: 5rem 0;
	.box(grid; 100%; auto; none; 2rem; auto; auto; center; center);
	& > h1 {
		.grid(1 span);
		.text(inherit; 1em 8rem @bold; center; uppercase none);
	}
	& > .Description {
		.grid(1 span);
		.text(inherit; 1.4em 1.5rem @regular; center; none none);
	}
	& > .UiButton {
		.grid(1 span);
	}
}
</style>
